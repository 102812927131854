var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lg:text-xl for-men home relative text-base"},[_c('a',{staticClass:"fixed bottom-0 right-0 z-50 mb-4 mr-4 bg-gray-800 border border-white rounded-full",attrs:{"href":"#"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"48","height":"48"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z","fill":"rgba(255,255,255,1)"}})])]),_c('SiteHeader'),_c('div',{staticClass:"spacer lg:pt-0 pt-8"}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('SiteDoctor'),_c('SiteConsultation'),_c('SiteForm'),_c('SiteFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"lg:pt-24 lg:bg-cover bg-sub-banner relative pt-12 overflow-hidden text-gray-800 bg-center"},[_c('div',{staticClass:"md:w-1/3 lg:w-1/2 bg-men absolute top-0 right-0 w-1/2 h-full bg-left bg-cover"}),_c('div',{staticClass:"max-w-screen-xl px-4 py-16 mx-auto"},[_c('h2',{staticClass:"lg:font-thin lg:text-6xl text-4xl font-light"},[_vm._v("For Men")]),_c('p',{staticClass:"lg:text-base text-sm"},[_vm._v("Home > "),_c('span',{staticClass:"text-sitePurple font-bold"},[_vm._v("For Men")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex w-full"},[_c('div',{staticClass:"bg-siteOPurple w-1/3 py-2"}),_c('div',{staticClass:"bg-sitePPurple w-1/3 py-2"}),_c('div',{staticClass:"bg-sitePurple w-1/3 py-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-gray-800"},[_c('div',{staticClass:"md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 max-w-screen-xl px-4 pt-12 pb-8 mx-auto"},[_c('div',{staticClass:"lg:items-center flex flex-wrap -mx-4 overflow-hidden"},[_c('div',{staticClass:"w-full px-4 overflow-hidden"},[_c('p',{staticClass:"mb-8"},[_vm._v(" Men are an increasingly growing group of patients that Dr Marco sees. From those seeking a small enhancement to those looking for a complete rejuvenation, many men turn to an experienced doctor like Dr Marco for a safe and effective makeover. ")]),_c('a',{staticClass:"lg:text-xl text-sitePurple inline-block w-full mb-4 text-lg text-left",attrs:{"href":"tel:+6564648075"}},[_vm._v("To find out more about Dr Marco’s personalized treatments for men, give us a call at +65 6464 8075 now.")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-gray-800 bg-gray-200"},[_c('div',{staticClass:"lg:px-24"},[_c('div',{staticClass:"lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto"},[_c('div',{staticClass:"lg:-mx-4 flex flex-wrap overflow-hidden"},[_c('div',{staticClass:"md:w-1/2 w-full px-4 overflow-hidden"},[_c('img',{staticClass:"lg:ml-auto mb-4",attrs:{"src":"images/image-men-01.jpg"}})]),_c('div',{staticClass:"md:w-1/2 w-full px-4 overflow-hidden"},[_c('div',{staticClass:"lg:pl-8"},[_c('h4',{staticClass:"lg:mb-6 lg:text-2xl text-sitePurple mb-4 text-xl font-bold uppercase"},[_vm._v("Gynecomastia")]),_c('p',{staticClass:"mb-4"},[_vm._v(" Gynecomastia, also known as enlarged male breasts, occur when excess deposits of localized fat accumulate within the chest. Using tiny incisions, Dr Marco removes the fat through liposuction while tightening and removing excess skin for a contoured, firmer chest. ")]),_c('p',{staticClass:"lg:text-xl mb-4 text-lg font-bold"},[_vm._v("Some common signs of gynecomastia are:")]),_c('ul',{staticClass:"pl-5 mb-4 list-disc"},[_c('li',{staticClass:"mb-1"},[_vm._v("Localized fat deposits at the chest")]),_c('li',{staticClass:"mb-1"},[_vm._v("Overabundance of glandular tissue in the breast")]),_c('li',[_vm._v("Excess breast skin")])]),_c('p',{staticClass:"lg:text-xl mb-4 text-lg font-bold"},[_vm._v("Reasons for choosing Dr Marco include:")]),_c('ul',{staticClass:"pl-5 mb-4 list-disc"},[_c('li',{staticClass:"mb-1"},[_vm._v("Quick surgery of 1-2 hours")]),_c('li',{staticClass:"mb-1"},[_vm._v("Outpatient procedure allowing home rest")]),_c('li',{staticClass:"mb-1"},[_vm._v("Experienced surgeon for safer results")]),_c('li',[_vm._v("Dedicated to sculpting and designing a masculine male breast shape")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-gray-800"},[_c('div',{staticClass:"lg:px-24"},[_c('div',{staticClass:"lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto"},[_c('div',{staticClass:"lg:-mx-4 flex flex-wrap overflow-hidden"},[_c('div',{staticClass:"md:w-1/2 w-full px-4 overflow-hidden"},[_c('img',{staticClass:"lg:ml-auto mb-4",attrs:{"src":"images/image-men-02.jpg"}})]),_c('div',{staticClass:"md:w-1/2 w-full px-4 overflow-hidden"},[_c('div',{staticClass:"lg:pl-8"},[_c('h4',{staticClass:"lg:mb-6 lg:text-2xl text-sitePurple mb-4 text-xl font-bold uppercase"},[_vm._v("Abdominoplasty")]),_c('p',{staticClass:"mb-4"},[_vm._v(" Contrary to popular belief, many men also undergo abdominoplasty due to stretched abdominal muscles. Abdominoplasty/tummy tucks may be eligible for insurance claims if muscle repair is required. ")]),_c('p',{staticClass:"lg:text-xl mb-4 text-lg font-bold"},[_vm._v("This is suitable for men who:")]),_c('ul',{staticClass:"pl-5 mb-4 list-disc"},[_c('li',{staticClass:"mb-1"},[_vm._v("Have stubborn excess fat around the midsection")]),_c('li',{staticClass:"mb-1"},[_vm._v("Are not overweight")]),_c('li',{staticClass:"mb-1"},[_vm._v("Want to regain a flat abdomen")]),_c('li',[_vm._v("Who desire stronger, stabler spine and core muscles")])]),_c('p',{staticClass:"lg:text-xl mb-4 text-lg font-bold"},[_vm._v("Reasons for choosing Dr Marco include:")]),_c('ul',{staticClass:"pl-5 mb-4 list-disc"},[_c('li',{staticClass:"mb-1"},[_vm._v("Variety of abdominoplasty methods available")]),_c('li',{staticClass:"mb-1"},[_vm._v("Experienced surgeon for safer results")]),_c('li',[_vm._v("Thorough, safe procedure to remove excess fat and skin")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-gray-800 bg-gray-200"},[_c('div',{staticClass:"lg:px-24"},[_c('div',{staticClass:"lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto"},[_c('div',{staticClass:"lg:-mx-4 flex flex-wrap overflow-hidden"},[_c('div',{staticClass:"md:w-1/2 w-full px-4 overflow-hidden"},[_c('img',{staticClass:"lg:ml-auto mb-4",attrs:{"src":"images/image-men-03.jpg"}})]),_c('div',{staticClass:"md:w-1/2 w-full px-4 overflow-hidden"},[_c('div',{staticClass:"lg:pl-8"},[_c('h4',{staticClass:"lg:mb-6 lg:text-2xl text-sitePurple mb-4 text-xl font-bold uppercase"},[_vm._v("Liposuction")]),_c('p',{staticClass:"mb-4"},[_vm._v(" Men often face stubborn localized fat deposits as they age. Fortunately, these can be removed and liposculpting can be performed to achieve a slimmer, trimmer figure. ")]),_c('p',{staticClass:"lg:text-xl mb-4 text-lg font-bold"},[_vm._v("This is suitable for men who:")]),_c('ul',{staticClass:"pl-5 mb-4 list-disc"},[_c('li',{staticClass:"mb-1"},[_vm._v("Are prone to pockets of fat that do not respond to exercise or dieting")]),_c('li',{staticClass:"mb-1"},[_vm._v("Have more fat deposits found in the thighs, tummy and midsection")]),_c('li',[_vm._v("Desire increased confidence and better fitting clothes")])]),_c('p',{staticClass:"lg:text-xl mb-4 text-lg font-bold"},[_vm._v("Reasons for choosing Dr Marco include:")]),_c('ul',{staticClass:"pl-5 mb-4 list-disc"},[_c('li',{staticClass:"mb-1"},[_vm._v("Effective “fine-tuning” of troublesome areas")]),_c('li',{staticClass:"mb-1"},[_vm._v("Increased confidence with Dr Marco’s international experience")]),_c('li',[_vm._v("Complete post-procedure care")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-gray-800"},[_c('div',{staticClass:"lg:px-24"},[_c('div',{staticClass:"lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto"},[_c('div',{staticClass:"lg:-mx-4 flex flex-wrap overflow-hidden"},[_c('div',{staticClass:"md:w-1/2 w-full px-4 overflow-hidden"},[_c('img',{staticClass:"lg:ml-auto mb-4",attrs:{"src":"images/image-men-05.jpg"}})]),_c('div',{staticClass:"md:w-1/2 w-full px-4 overflow-hidden"},[_c('div',{staticClass:"lg:pl-8"},[_c('h4',{staticClass:"lg:mb-6 lg:text-2xl text-sitePurple mb-4 text-xl font-bold uppercase"},[_vm._v("Other Facial Surgeries ")]),_c('p',{staticClass:"lg:text-xl mb-4 text-lg font-bold"},[_vm._v("Some other facial treatments that men can also benefit from include:")]),_c('ul',{staticClass:"pl-5 mb-4 list-disc"},[_c('li',{staticClass:"mb-1"},[_vm._v("Dermal filler injections")]),_c('li',{staticClass:"mb-1"},[_vm._v("Fat grafting")]),_c('li',{staticClass:"mb-1"},[_vm._v("Wrinkle and expression line removal")]),_c('li',{staticClass:"mb-1"},[_vm._v("Facelift")]),_c('li',{staticClass:"mb-1"},[_vm._v("Neck lift")]),_c('li',{staticClass:"mb-1"},[_vm._v("Brow lift")]),_c('li',{staticClass:"mb-1"},[_vm._v("Upper and Lower Eyelid Surgery")]),_c('li',[_vm._v("Rhinoplasty")])])])])])])])])
}]

export { render, staticRenderFns }
<template>
  <div class="lg:text-xl for-men home relative text-base">
    <a href="#" class="fixed bottom-0 right-0 z-50 mb-4 mr-4 bg-gray-800 border border-white rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" fill="rgba(255,255,255,1)" />
      </svg>
    </a>
    <SiteHeader />
    <div class="spacer lg:pt-0 pt-8"></div>
    <section class="lg:pt-24 lg:bg-cover bg-sub-banner relative pt-12 overflow-hidden text-gray-800 bg-center">
      <div class="md:w-1/3 lg:w-1/2 bg-men absolute top-0 right-0 w-1/2 h-full bg-left bg-cover"></div>
      <div class="max-w-screen-xl px-4 py-16 mx-auto">
        <h2 class="lg:font-thin lg:text-6xl text-4xl font-light">For Men</h2>
        <p class="lg:text-base text-sm">Home > <span class="text-sitePurple font-bold">For Men</span></p>
      </div>
    </section>
    <div class="flex w-full">
      <div class="bg-siteOPurple w-1/3 py-2"></div>
      <div class="bg-sitePPurple w-1/3 py-2"></div>
      <div class="bg-sitePurple w-1/3 py-2"></div>
    </div>
    <section class="text-gray-800">
      <div class="md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 max-w-screen-xl px-4 pt-12 pb-8 mx-auto">
        <div class="lg:items-center flex flex-wrap -mx-4 overflow-hidden">
          <div class="w-full px-4 overflow-hidden">
            <p class="mb-8">
              Men are an increasingly growing group of patients that Dr Marco sees. From those seeking a small
              enhancement to those looking for a complete rejuvenation, many men turn to an experienced doctor like Dr
              Marco for a safe and effective makeover.
            </p>
            <a href="tel:+6564648075" class="lg:text-xl text-sitePurple inline-block w-full mb-4 text-lg text-left">To
              find out more about Dr Marco’s personalized treatments for men, give us a call at +65 6464 8075 now.</a>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800 bg-gray-200">
      <div class="lg:px-24">
        <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="lg:ml-auto mb-4" src="images/image-men-01.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:mb-6 lg:text-2xl text-sitePurple mb-4 text-xl font-bold uppercase">Gynecomastia</h4>
                <p class="mb-4">
                  Gynecomastia, also known as enlarged male breasts, occur when excess deposits of localized fat
                  accumulate within the chest. Using tiny incisions, Dr Marco removes the fat through liposuction while
                  tightening and removing excess skin for a contoured, firmer chest.
                </p>
                <p class="lg:text-xl mb-4 text-lg font-bold">Some common signs of gynecomastia are:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Localized fat deposits at the chest</li>
                  <li class="mb-1">Overabundance of glandular tissue in the breast</li>
                  <li>Excess breast skin</li>
                </ul>
                <p class="lg:text-xl mb-4 text-lg font-bold">Reasons for choosing Dr Marco include:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Quick surgery of 1-2 hours</li>
                  <li class="mb-1">Outpatient procedure allowing home rest</li>
                  <li class="mb-1">Experienced surgeon for safer results</li>
                  <li>Dedicated to sculpting and designing a masculine male breast shape</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800">
      <div class="lg:px-24">
        <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="lg:ml-auto mb-4" src="images/image-men-02.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:mb-6 lg:text-2xl text-sitePurple mb-4 text-xl font-bold uppercase">Abdominoplasty</h4>
                <p class="mb-4">
                  Contrary to popular belief, many men also undergo abdominoplasty due to stretched abdominal muscles.
                  Abdominoplasty/tummy tucks may be eligible for insurance claims if muscle repair is required.
                </p>
                <p class="lg:text-xl mb-4 text-lg font-bold">This is suitable for men who:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Have stubborn excess fat around the midsection</li>
                  <li class="mb-1">Are not overweight</li>
                  <li class="mb-1">Want to regain a flat abdomen</li>
                  <li>Who desire stronger, stabler spine and core muscles</li>
                </ul>
                <p class="lg:text-xl mb-4 text-lg font-bold">Reasons for choosing Dr Marco include:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Variety of abdominoplasty methods available</li>
                  <li class="mb-1">Experienced surgeon for safer results</li>
                  <li>Thorough, safe procedure to remove excess fat and skin</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800 bg-gray-200">
      <div class="lg:px-24">
        <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="lg:ml-auto mb-4" src="images/image-men-03.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:mb-6 lg:text-2xl text-sitePurple mb-4 text-xl font-bold uppercase">Liposuction</h4>
                <p class="mb-4">
                  Men often face stubborn localized fat deposits as they age. Fortunately, these can be removed and
                  liposculpting can be performed to achieve a slimmer, trimmer figure.
                </p>
                <p class="lg:text-xl mb-4 text-lg font-bold">This is suitable for men who:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Are prone to pockets of fat that do not respond to exercise or dieting</li>
                  <li class="mb-1">Have more fat deposits found in the thighs, tummy and midsection</li>
                  <li>Desire increased confidence and better fitting clothes</li>
                </ul>
                <p class="lg:text-xl mb-4 text-lg font-bold">Reasons for choosing Dr Marco include:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Effective “fine-tuning” of troublesome areas</li>
                  <li class="mb-1">Increased confidence with Dr Marco’s international experience</li>
                  <li>Complete post-procedure care</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800">
      <div class="lg:px-24">
        <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="lg:ml-auto mb-4" src="images/image-men-05.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:mb-6 lg:text-2xl text-sitePurple mb-4 text-xl font-bold uppercase">Other Facial Surgeries
                </h4>
                <p class="lg:text-xl mb-4 text-lg font-bold">Some other facial treatments that men can also benefit from
                  include:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Dermal filler injections</li>
                  <li class="mb-1">Fat grafting</li>
                  <li class="mb-1">Wrinkle and expression line removal</li>
                  <li class="mb-1">Facelift</li>
                  <li class="mb-1">Neck lift</li>
                  <li class="mb-1">Brow lift</li>
                  <li class="mb-1">Upper and Lower Eyelid Surgery</li>
                  <li>Rhinoplasty</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <SiteDoctor />
    <SiteConsultation />
    <SiteForm />
    <SiteFooter />
  </div>
</template>

<script>
  import SiteHeader from '@/components/SiteHeader.vue'
  import SiteDoctor from '@/components/SiteDoctor.vue'
  import SiteConsultation from '@/components/SiteConsultation.vue'
  import SiteForm from '@/components/SiteForm.vue'
  import SiteFooter from '@/components/SiteFooter.vue'
  export default {
    components: {
      SiteHeader,
      SiteDoctor,
      SiteConsultation,
      SiteForm,
      SiteFooter
    },
    data() {
      return {
        index: 0,
      }
    }
  }
</script>

<style>
  .bg-sub-banner {
    background-image: url(/images/bg-sub-banner.jpg);
  }
  .bg-men {
    background-image: url(/images/bg-men.png);
  }
</style>